import React, { useContext, useMemo } from 'react'
import { ConfigContext } from 'src/store/ConfigContext'

export const UserContext = React.createContext({
  selectedBizModel: null,
  accountId: null,
  selectedDeviceLocation: null,
  selectedDeviceName: null,
  selectedProgramId: null
})

const UserProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const selectedBizModel = sessionContext?.user?.selectedBizModel || null
  const accountId = sessionContext?.tenant?.resourceId || null
  const selectedProgramId = sessionContext?.user?.selectedProgramId || null
  const selectedDeviceLocation =
    sessionContext?.user?.selectedDeviceLocation || null
  const selectedDeviceName = sessionContext?.user?.selectedDeviceName || null

  const state = useMemo(
    () => ({
      selectedBizModel,
      accountId,
      selectedDeviceLocation,
      selectedDeviceName,
      selectedProgramId
    }),
    [
      selectedBizModel,
      accountId,
      selectedDeviceLocation,
      selectedDeviceName,
      selectedProgramId
    ]
  )

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  )
}

export default UserProvider
