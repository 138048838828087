import React, { useContext, useState, useEffect, Suspense } from 'react'
import classes from './Spinner.module.scss'
import {
  BIZ_MODEL,
  ContentStackContentTypes,
  ContentStackPrinterTypes
} from 'src/config/constants'
import { UserContext } from 'src/store/UserContext'
import { ConfigContext } from 'src/store/ConfigContext'
import { PrinterContext } from 'src/store/PrinterContext'
import { ActivationContext } from 'src/store/ActivationContext'
import useContentStack from 'src/hooks/useContentStack'
import {
  IconInkDrop,
  IconCloud,
  IconEnvelope,
  IconBell,
  IconDashboard
} from 'src/utils/lazyVeneer'
import { nanoid } from 'nanoid'
import {
  UiTheme,
  useMultiUiBrandContext,
  ProgressIndicator
} from '@jarvis/react-setup-and-onboarding-addons'

const Default = () => {
  const { selectedBizModel } = useContext(UserContext)
  const { direction, getText } = useContext(ConfigContext)
  const bizModelCopy =
    selectedBizModel === BIZ_MODEL.E2E ? 'activate' : 'register'
  const { uiBrand } = useMultiUiBrandContext()
  const isHpx = uiBrand === UiTheme.hpx
  const selectedClass = isHpx
    ? {
        indicator: classes.linearIndicator,
        appearance: 'linear',
        label: classes.labelHpx,
        testId: 'spinner-linear'
      }
    : {
        indicator: classes.circularIndicator,
        appearance: 'circular',
        label: '',
        testId: 'spinner-circular'
      }

  return (
    <div
      className={classes.spinner}
      dir={direction}
    >
      <div className={`${classes.container} ${selectedClass.indicator}`}>
        <ProgressIndicator
          data-testid={selectedClass.testId}
          appearance={`${selectedClass.appearance}`}
          behavior="indeterminate"
          size={isHpx ? 'large' : 'small'}
        />
        {bizModelCopy && (
          <label className={`${classes.label} ${selectedClass.label}`}>
            {getText(`spinner.${bizModelCopy}.0`)}
          </label>
        )}
      </div>
    </div>
  )
}

const SmbProgressBar = () => {
  const { totalPhases, phase } = useContext(ActivationContext)
  const { isMultiFunction } = useContext(PrinterContext)
  const { direction } = useContext(ConfigContext)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [progressInterval, setProgressInterval] = useState(null)

  /**
   *   Interval for updating progress bar
   *   Initial and arbitrary design: 90% in 60 seconds. 1.5% per second, or .375% every 1/4 second
   */
  useEffect(() => {
    if (!progressInterval) {
      const interval = setInterval(() => {
        // Still below 60 second threshold
        setCurrentProgress((prev) => prev + 0.375)
      }, 250)
      setProgressInterval(interval)
      return
    }

    if (phase === totalPhases) {
      // Last phase, 100%
      setCurrentProgress(100)
      clearInterval(progressInterval)
      return
    }

    if (currentProgress >= 90) {
      // No more progress to make per design
      clearInterval(progressInterval)
    }
  }, [phase, progressInterval, currentProgress, totalPhases])

  const getIcon = (icon) => {
    switch (icon) {
      case 'ink':
        return <IconInkDrop size={24} />
      case 'cloud':
        return <IconCloud size={24} />
      case 'email':
        return <IconEnvelope size={24} />
      case 'alert':
        return <IconBell size={24} />
      default:
        return <IconDashboard size={24} />
    }
  }

  const parseContentStackData = (response) => {
    const parsedEntitlements = []
    response.entitlements.forEach((entry) => {
      parsedEntitlements.push({
        icon: entry.entitlement.icon,
        title: entry.entitlement.title,
        description: entry.entitlement.description
      })
    })
    return {
      header: response.header,
      subheader: response.subheader,
      entitlements: parsedEntitlements
    }
  }

  const { pageData, init } = useContentStack(
    ContentStackContentTypes.smb_activation,
    parseContentStackData,
    {
      printer_type: isMultiFunction
        ? ContentStackPrinterTypes.multi_function
        : ContentStackPrinterTypes.single_function
    },
    true
  )

  useEffect(() => {
    if (isMultiFunction !== null) {
      init()
    }
  }, [isMultiFunction, init])

  return (
    <div
      className={classes.progressBar}
      dir={direction}
    >
      <div className={classes.header}>
        <p>{pageData?.header}</p>
      </div>
      <div className={classes.body}>
        <div className={classes.linearIndicator}>
          <ProgressIndicator
            data-testid="progress-bar"
            appearance="linear"
            behavior="determinate"
            value={currentProgress}
          />
        </div>
        <div className={classes.entitlements}>
          <p>{pageData?.subheader}</p>
          <div className={classes.entitlementsList}>
            {pageData?.entitlements.map((entitlement) => (
              <div
                key={nanoid()}
                className={classes.entitlement}
              >
                <div className={classes.icon}>
                  <Suspense fallback={<></>}>
                    {getIcon(entitlement.icon)}
                  </Suspense>
                </div>
                <div className={classes.description}>
                  <p className={classes.title}>{entitlement.title}</p>
                  <p>{entitlement.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Spinner = () => {
  const { isSmbSmallville } = useContext(ConfigContext)

  if (isSmbSmallville)
    return (
      <Suspense fallback={<></>}>
        <SmbProgressBar />
      </Suspense>
    )

  return (
    <Suspense fallback={<></>}>
      <Default />
    </Suspense>
  )
}

export default Spinner
