import { RESOURCE_URI_FALLBACK } from 'src/config/constants'
import { Logger } from '../helpers'

const { CDM: URI } = RESOURCE_URI_FALLBACK

const getHref = (service, rel) => {
  try {
    const serviceLink = service['links'].find((link) => link['rel'] === rel)
    // Workaround to also handle hrefTemplate until LOWINKFW-14199 is fixed
    return serviceLink?.hrefTemplate
      ? serviceLink.hrefTemplate
      : serviceLink.href
  } catch (e) {
    let log = `Not able to find action href for rel ${rel} - ${e}`
    if (service) log += ` - ${JSON.stringify(service)}`
    Logger.warn(log)
    return null
  }
}

const getService = (servicesDiscovery, resourceGun) => {
  try {
    return servicesDiscovery['services'].find(
      (service) => service['serviceGun'] === resourceGun
    )
  } catch (e) {
    let log = `Not able to find service ${resourceGun} - ${e}`
    if (servicesDiscovery) log += ` - ${JSON.stringify(servicesDiscovery)}`
    Logger.warn(log)
    return null
  }
}

export const getSystemIdentityHref = (servicesDiscovery) => {
  return (
    getHref(
      getService(servicesDiscovery, 'com.hp.cdm.service.system.version.1'),
      'identity'
    ) || URI.systemIdentity
  )
}

export const getAvatarRegistrationHref = (
  servicesDiscovery,
  fallback = true
) => {
  return (
    getHref(
      getService(
        servicesDiscovery,
        'com.hp.cdm.service.cloudServices.version.1'
      ),
      'avatarRegistration'
    ) || (fallback ? URI.avatarRegistration : null)
  )
}

export const getClaimPostcardHref = (servicesDiscovery, fallback = true) => {
  return (
    getHref(
      getService(
        servicesDiscovery,
        'com.hp.cdm.service.cloudServices.version.1'
      ),
      'claimPostcard'
    ) || (fallback ? URI.claimPostcard : null)
  )
}

export const getFingerprintHref = (servicesDiscovery, fallback = true) => {
  return (
    getHref(
      getService(
        servicesDiscovery,
        'com.hp.cdm.service.deviceProvisioning.version.1'
      ),
      'printerFingerprint'
    ) || (fallback ? URI.fingerprint : null)
  )
}

export const getInternetDiagnosticsHref = (
  servicesDiscovery,
  fallback = true
) => {
  return (
    getHref(
      getService(servicesDiscovery, 'com.hp.cdm.service.network.version.1'),
      'internetDiagnostics'
    ) || (fallback ? URI.internetDiagnostics : null)
  )
}
